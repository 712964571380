<template>
  <div style="background-color: #f3f4f8;">
    <el-carousel height="2.6rem" :interval="8000">
      <el-carousel-item v-for="item in bannerlist" :key="item">
        <img :src="item" alt="" width="100%" />
      </el-carousel-item>
    </el-carousel>
    <div class="product">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">{{ producttit }}</div>
          <div class="tit2"></div>
        </div>
        <p class="product_con">
          梦宇3D将以先进的技术、一流的质量和精湛的产品和为广大客户提供满意的产品和服务
        </p>
      </div>
      <div class="product_card">
        <ul>
          <li v-for="(item, index) in productlist" :key="index">
            <div class="card">
              <div class="card_info">
                <div class="card_tit">{{ item.articleTitle }}</div>
                <div class="card_con" v-html="item.articleContent"></div>
              </div>
              <div class="more">
                <span class="more_tit" @click="learnmore(item.path)">了解更多</span><img src="@/assets/images/01首页/箭头.png" alt="" class="more_img" />
              </div>
              <div class="card_img">
                <img :src="'http://36.152.65.166:10008'+item.articleCover" alt="" />
              </div>
            </div>
          </li>
          <!-- <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">数字文娱</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span><img src="../../assets/images/01首页/箭头.png" alt="" class="more_img" />
              </div>
              <div class="card_img">
                <img src="../../assets/images/01首页/数字文娱.jpg" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">数字医教</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span><img src="../../assets/images/01首页/箭头.png" alt="" class="more_img" />
              </div>
              <div class="card_img">
                <img src="../../assets/images/01首页/数字医教.jpg" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">VR_AR</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span><img src="../../assets/images/01首页/箭头.png" alt="" class="more_img" />
              </div>
              <div class="card_img">
                <img src="../../assets/images/01首页/VR_AR.jpg" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="card">
              <div class="card_info">
                <div class="card_tit">运动捕捉</div>
                <div class="card_con">
                  运用先进的运动捕捉技术，为数字影视、数字动画、数字游戏、数字广告等提供高质量、高效率、低成本的3D数字内容制作
                </div>
              </div>
              <div class="more">
                <span class="more_tit">了解更多</span><img src="../../assets/images/01首页/箭头.png" alt="" class="more_img" />
              </div>
              <div class="card_img">
                <img src="../../assets/images/01首页/运动捕捉.jpg" alt="" />
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <div class="company">
      <div class="company_tit">公司简介</div>
      <div class="company_btn" @click="checkList">查看详情</div>
    </div>
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">数字文娱</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="numberSwiper" :options="swiperOption1" id="myswiper">
          <swiper-slide v-for="(item,index) in casearr" :key="index">
            <div class="swiper_con">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>

        <div class="swiper-list">
          <div class="swiper-button-prev-diy" @click="numberPrevFn"></div>
          <div class="swiper-button-next-diy" @click="nmuberNextFn"></div>
        </div>
      </div>
    </div>
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">虚拟仿真</div>
          <div class="tit2"></div>
        </div>
      </div>

      <div class="sportswiper">
        <swiper ref="xnSwiper" :options="swiperOption2" id="myswiper">
          <swiper-slide v-for="(item,index) in xncasearr" :key="index">
            <div class="swiper_con" @click="routerlinkXncase(item.articleId,index)">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>
        <div class="swiper-list">
          <div class="swiper-button-prev-diy" @click="xnPrevFn"></div>
          <div class="swiper-button-next-diy" @click="xnNextFn"></div>
        </div>

      </div>
    </div>
    <div class="sport">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">运动捕捉</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="swiper_tit">
        <ul>
          <li v-for="(item,index) in titlist" :key="index" :class="{blue:index===currindex}" @click="addclass(index)">
            <div :class="`swiper_img${index+1}`"></div>
            <span>{{item.articleTitle}}</span>
          </li>

        </ul>
      </div>
      <div class="sportswiper">
        <swiper ref="mySwiper" :options="swiperOption66" id="myswiper">
          <swiper-slide v-for="(item,index) in sportlist[currindex]" :key="index">
            <div class="swiper_con" @click="routerlinkSport(currindex,item.articleTitle,index)">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>
        <div class="swiper-list">
          <div class="swiper-button-prev-diy" @click="prevFn"></div>
          <div class="swiper-button-next-diy" @click="nextFn"></div>
        </div>

      </div>
    </div>
    <!-- <div class="news">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">新闻动态</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="newslist">
        <ul>
          <li>
            <div class="newsimg">
              <img src="../../assets/images/03新闻动态/方彤：现实与虚拟场景“全”交互体验.png" alt="" />
            </div>
            <div class="newscon">
              <div class="newcon_tit">方彤：现实与虚拟场景“全”交互体验</div>
              <div class="newcon_txt">
                第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽+。。。。。。。”
              </div>
            </div>
            <div class="newsinfo">
              <div class="newssee">
                <img src="../../assets/images/01首页/浏览.png" alt="" />
                <span>132</span>
              </div>
              <div class="newsnice">
                <img src="../../assets/images/01首页/点赞.png" alt="" />
                <span>86</span>
              </div>
              <div class="newstime">
                <img src="../../assets/images/01首页/发布日期.png" alt="" />
                <span>2019-04-16</span>
              </div>
            </div>
          </li>
          <li>
            <div class="newsimg">
              <img src="../../assets/images/03新闻动态/梦宇3D 亮相国家“十二五”科技创新成就展.png" alt="" />
            </div>
            <div class="newscon">
              <div class="newcon_tit">
                梦宇3D 亮相国家“十二五”科技创新成就展
              </div>
              <div class="newcon_txt">
                第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽+。。。。。。。”
              </div>
            </div>
            <div class="newsinfo">
              <div class="newssee">
                <img src="../../assets/images/01首页/浏览.png" alt="" />
                <span>132</span>
              </div>
              <div class="newsnice">
                <img src="../../assets/images/01首页/点赞.png" alt="" />
                <span>86</span>
              </div>
              <div class="newstime">
                <img src="../../assets/images/01首页/发布日期.png" alt="" />
                <span>2019-04-16</span>
              </div>
            </div>
          </li>
          <li>
            <div class="newsimg">
              <img src="../../assets/images/03新闻动态/中影梦宇实时动作捕捉中心.png" alt="" />
            </div>
            <div class="newscon">
              <div class="newcon_tit">中影梦宇实时动作捕捉中心</div>
              <div class="newcon_txt">
                第三届全球传感器高峰论坛暨中国物联网应用峰会今天在合肥召开。以“聚集真知灼见，共谋产业发展”为主题，以“高峰论坛+展览展示+投资洽+。。。。。。。”
              </div>
            </div>
            <div class="newsinfo">
              <div class="newssee">
                <img src="../../assets/images/01首页/浏览.png" alt="" />
                <span>132</span>
              </div>
              <div class="newsnice">
                <img src="../../assets/images/01首页/点赞.png" alt="" />
                <span>86</span>
              </div>
              <div class="newstime">
                <img src="../../assets/images/01首页/发布日期.png" alt="" />
                <span>2019-04-16</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="morenews">了解更多</div>
    </div>
    <div class="cases">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">经典案例</div>
          <div class="tit2"></div>
        </div>
        <p class="product_con">经典源自于自然的恩赐，更融合了人类的智慧。</p>
      </div>
      <div class="caselist">
        <ul>
          <li>
            <div class="caseimg">
              <img src="../../assets/images/08经典案例/临床技能中心综合管理系统.png" alt="" />
            </div>
            <div class="casetit">临床技能中心综合管理系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img src="../../assets/images/08经典案例/VR游戏.png" alt="" />
            </div>
            <div class="casetit">VR游戏</div>
          </li>
          <li>
            <div class="caseimg">
              <img src="../../assets/images/08经典案例/智能数字化病人.png" alt="" />
            </div>
            <div class="casetit">智能数字化病人</div>
          </li>
          <li>
            <div class="caseimg">
              <img src="../../assets/images/08经典案例/融合投影.png" alt="" />
            </div>
            <div class="casetit">融合投影</div>
          </li>
        </ul>
      </div>
    </div> -->
    <div class="partners">
      <div class="partners_title">
        <div class="titline"></div>
        <div class="partners_tit">合作伙伴</div>
        <div class="titline"></div>
      </div>
      <ul class="partners_img">
        <li class="friendimg" v-for="(item,index) in friendlist" :key="index">
          <img :src="item" alt="" />
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { getbannerimg, getbannerdetail } from "@/http/api/user.js";

import friend_img1 from "@/assets/images/01首页/合作伙伴/腾讯游戏.png";
import friend_img2 from "@/assets/images/01首页/合作伙伴/纵深网络.png";
import friend_img3 from "@/assets/images/01首页/合作伙伴/玩友时代.png";
import friend_img4 from "@/assets/images/01首页/合作伙伴/Igg.png";
import friend_img5 from "@/assets/images/01首页/合作伙伴/冬竞.png";
import friend_img6 from "@/assets/images/01首页/合作伙伴/中影动画.png";
import friend_img7 from "@/assets/images/01首页/合作伙伴/原力.png";
import friend_img8 from "@/assets/images/01首页/合作伙伴/舞之动画.png";
import friend_img9 from "@/assets/images/01首页/合作伙伴/叠纸暖暖.png";
import friend_img10 from "@/assets/images/01首页/合作伙伴/黑将网络.png";
import friend_img11 from "@/assets/images/01首页/合作伙伴/水晶石.png";
import friend_img12 from "@/assets/images/01首页/合作伙伴/莉莉丝游戏.png";
import friend_img13 from "@/assets/images/01首页/合作伙伴/盛大游戏.png";
import friend_img14 from "@/assets/images/01首页/合作伙伴/巨人网络2.png";
import friend_img15 from "@/assets/images/01首页/合作伙伴/利亚德.png";
import friend_img16 from "@/assets/images/01首页/合作伙伴/铁科院.png";
import friend_img17 from "@/assets/images/01首页/合作伙伴/首都医科大学护理学院.png";
import friend_img18 from "@/assets/images/01首页/合作伙伴/上海十院.png";
import friend_img19 from "@/assets/images/01首页/合作伙伴/西南医院.png";
import friend_img20 from "@/assets/images/01首页/合作伙伴/上海健康医学院.png";
import friend_img21 from "@/assets/images/01首页/合作伙伴/北京清华长庚医院.png";
import friend_img22 from "@/assets/images/01首页/合作伙伴/附件中医药大学.png";
import friend_img23 from "@/assets/images/01首页/合作伙伴/江苏医药职业学院.png";
import friend_img24 from "@/assets/images/01首页/合作伙伴/国家电网.png";
import friend_img25 from "@/assets/images/01首页/合作伙伴/首都医科大学.png";
import friend_img26 from "@/assets/images/01首页/合作伙伴/北京协和医院.png";
import friend_img27 from "@/assets/images/01首页/合作伙伴/北京体育大学.png";
import friend_img28 from "@/assets/images/01首页/合作伙伴/中国中医药出版社.png";
import friend_img29 from "@/assets/images/01首页/合作伙伴/上海九院.png";
import friend_img30 from "@/assets/images/01首页/合作伙伴/湘雅二院.png";
export default {
  data() {
    return {
      // 轮播图
      bannerlist: [],
      // 产品标题
      producttit: "",
      // 产品分类
      productlist: [],
      // 数字文娱
      casearr: [],
      // 虚拟仿真
      xncasearr: [],
      // 运动捕捉标题
      titlist: [],
      // 运动捕捉下标
      currindex: 0,
      // 运动捕捉列表
      sportlist: [],
      Newsportlist: [],
      // 解决方案
      solvelist: [],
      // 轮播
      swiperOption1: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // // 设置轮播可循环
        // loop: true,
      },
      swiperOption2: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // // 设置轮播可循环
        // loop: true,
      },
      swiperOption66: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 5秒切换一次
        },
        // // 设置轮播可循环
        // loop: true,
      },
      // 合作伙伴
      friendlist: [
        friend_img1,
        friend_img2,
        friend_img3,
        friend_img4,
        friend_img5,
        friend_img6,
        friend_img7,
        friend_img8,
        friend_img9,
        friend_img10,
        friend_img11,
        friend_img12,
        friend_img13,
        friend_img14,
        friend_img15,
        friend_img16,
        friend_img17,
        friend_img18,
        friend_img19,
        friend_img20,
        friend_img21,
        friend_img22,
        friend_img23,
        friend_img24,
        friend_img25,
        friend_img26,
        friend_img27,
        friend_img28,
        friend_img29,
        friend_img30,
      ],
    };
  },
  created() {},
  mounted() {
    // 轮播图
    this.init();
    // 产品详情
    this.getdetail();
    // 数字文娱.
    this.getEntertainment();
    // 虚拟仿真
    this.getSimulation();
    // 运动捕捉
    this.getSport();
  },
  computed: {
    // 数字文娱
    numberSwiper() {
      return this.$refs.numberSwiper.$swiper;
    },
    // 虚拟仿真
    xnSwiper() {
      return this.$refs.xnSwiper.$swiper;
    },
    // 运动捕捉
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    checkList() {
      this.$router.push({
        path: "/m_aboutus",
      });
    },
    //了解更多
    learnmore(path) {
      this.$router.push({
        path: path,
      });
    },
    //获取轮播图
    async init() {
      let params = {};
      const data = await getbannerimg(params);

      let arr = [];
      for (let i = 0; i < data.data.length; i++) {
        arr[i] = "http://36.152.65.166:10008" + data.data[i].image;
      }
      this.bannerlist = arr;
    },
    //获取产品详情
    async getdetail() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      var res = data.data.portalArticleList;
      this.producttit = res[0].articleTitle;
      this.productlist = res[0].children;
      this.productlist[0].path = "/m_entertainment/game";
      this.productlist[1].path = "/m_medicaleducation/info";
      this.productlist[2].path = "/m_sport/system";
    },
    //获取数字文娱
    async getEntertainment() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      this.casearr = data.data.portalArticleList[1].children;
      this.casearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },
    //数字文娱分页
    numberPrevFn() {
      this.numberSwiper.slidePrev();
    },
    nmuberNextFn() {
      this.numberSwiper.slideNext();
    },
    //获取虚拟仿真
    async getSimulation() {
      let params = {
        barId: 7,
      };
      const data = await getbannerdetail(params);
      this.xncasearr = data.data.children[0].portalArticleList;
      this.xncasearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
      // console.log(this.xncasearr, "---");
    },
    // 虚拟仿真分页
    xnPrevFn() {
      this.xnSwiper.slidePrev();
    },
    xnNextFn() {
      this.xnSwiper.slideNext();
    },

    // 虚拟仿真详情页
    routerlinkXncase(id, index) {
      this.$router.push({
        path: `/m_medicaleducation/infodetail`,
        query: {
          id: id,
          index: index,
        },
      });
    },
    //获取运动捕捉列表
    async getSport() {
      let params = {
        barId: 1,
      };
      const data = await getbannerdetail(params);
      this.titlist = data.data.portalArticleList[17].children;
      this.sportlist[0] = data.data.portalArticleList[18].children;
      this.sportlist[1] = data.data.portalArticleList[19].children;
      this.sportlist[2] = data.data.portalArticleList[20].children;
      for (let k = 0; k < this.sportlist.length; k++) {
        for (let t = 0; t < this.sportlist[k].length; t++) {
          let text = this.sportlist[k][t].articleCover;
          this.sportlist[k][t].articleCover =
            "http://36.152.65.166:10008" + text;
        }
      }
    },
    // 获取新的运动捕捉和新的解决方案
    async getcompany() {
      let params = {
        barId: 9,
      };
      const data = await getbannerdetail(params);
      this.Newsportlist = data.data.children[0].portalArticleList;
      this.solvelist = data.data.children[1].portalArticleList;
    },
    // 运动捕捉详情页
    async routerlinkSport(currindex, name, index) {
      if (this.Newsportlist.length == 0) {
        await this.getcompany();
      }
      if (currindex == 0) {
        this.Newsportlist.forEach((r, i) => {
          if (r.articleTitle == name) {
            this.$router.push({
              path: `/m_sport/system/systemdetail`,
              query: {
                id: r.articleId,
                index: i,
              },
            });
          } else {
            return;
          }
        });
      } else if (currindex == 1) {
        this.solvelist.forEach((r, i) => {
          if (r.articleTitle == name) {
            this.$router.push({
              path: `/m_sport/plan/plandetail`,
              query: {
                id: r.articleId,
                index: i,
              },
            });
          } else {
            return;
          }
        });
      } else {
        return;
      }
    },
    // 运动捕捉标题
    addclass(index) {
      this.currindex = index;
    },
    // 运动捕捉分页
    prevFn() {
      this.swiper.slidePrev();
    },
    nextFn() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang='scss'>
// 分页
.el-carousel__indicators {
  height: 0.5rem;

  display: flex;
  // align-self: center;
  // border: solid;
  // flex-direction: column;
  width: 50%;
  justify-content: center;
}
.el-carousel__item {
  background-color: #d3dce6;
}
.el-carousel__button {
  width: 0.4rem !important;
  height: 0.1rem !important;
}
.el-carousel__indicator.is-active button {
  background-color: #0088ea !important;
}
.swiper-pagination-bullet {
  width: 0.18rem !important;
  height: 0.18rem !important;
  opacity: 1 !important;
  background-color: #cccccc !important;
}
.swiper-container {
  width: 100% !important;

  overflow: initial;
}
.swiper-pagination {
  width: 99% !important;
  height: 4%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -5% !important;
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background-color: #666666 !important;
}
.swiper-list {
  height: 1rem;
}
</style>
<style lang="scss" scoped>
.product {
  .product_card {
    ul {
      // border: solid;
      // display: flex;
      margin: 0 auto;
      padding: 0.2rem;
      flex-wrap: wrap;

      // justify-content: space-evenly;
      align-items: center;
      .card {
        margin-top: 0.2rem;
        width: 100%;
        height: auto;
        border-top: 0.1rem solid #0088ea;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .card_info {
          height: 52%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .card_tit {
            margin-top: 0.1rem;
            font-size: 0.24rem;
            font-weight: bold;
          }
          .card_con {
            // border: solid;

            width: 90%;
            // overflow: hidden;
            font-size: 0.2rem;
          }
        }
        .more {
          .more_tit {
            font-size: 0.16rem;
            font-weight: bold;
            margin-right: 0.13rem;
          }
          display: flex;
          align-items: center;
          margin-left: 0.3rem;
        }
        .card_img {
          margin-top: 0.1rem;
          margin-left: 0.3rem;
          width: 90%;
          img {
            width: 100%;
          }
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // margin: 0 auto;
          // height: 1.34rem;
        }
      }
    }
  }
  width: 98%;
}
.product_title {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.6rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
  p {
    display: inline-block;
    width: 6rem;
    font-size: 0.24rem;
    text-align: center;
  }
}

.company {
  width: 100%;
  // height: 100%;
  // height: 2.5rem;
  background: url("~@/assets/images/01首页/公司简介.jpg") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .company_tit {
    font-size: 0.3rem;
    color: #fff;
    font-weight: bold;
  }
  .company_btn {
    padding: 0.1rem;
    color: #fff;
    font-size: 0.24rem;
    width: 2.4rem;
    // height: 0.rem;
    margin: 0.2rem 0rem 0.1rem 0rem;
    background-color: #0088ea;
    text-align: center;
    // line-height: 0.7rem;
    cursor: pointer;
  }
  .company_btn:hover {
    background-color: #047acf;
  }
}
.partners {
  background-color: #fff;
  .partners_title {
    display: flex;
    width: 100%;

    margin-top: 0.1rem;
    align-items: center;
    .titline {
      height: 0.01rem;
      width: 100%;
      background-color: #d2d2d2;
    }
    .partners_tit {
      width: 30%;
      font-size: 0.22rem;
      margin: 0 0.3rem;
    }
  }
  .partners_img {
    margin-top: 0.1rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-around;
    li {
      width: 47%;
      align-items: center;
      justify-content: center;
      display: flex;
      // margin-left: 0.1rem;
      margin-top: 0.1rem;
      // border: solid;
      height: 100%;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
  // border: solid;
  // margin-bottom: 0px;
}

.sport {
  background-color: #fff;
  // height: 11rem;
  .sportswiper {
    width: 7rem;
    // height: 7.8rem;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    .swiper-button-prev-diy,
    .swiper-button-next-diy {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      bottom: 0px;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
    }
    .swiper-button-prev-diy {
      left: 1.5rem;

      z-index: 9999;
      background: url("~@/assets/images/01首页/上一页1.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-prev-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/上一页2.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/下一页2.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next-diy {
      z-index: 9999;
      right: 1.5rem;
      background: url("~@/assets/images/01首页/下一页1.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper_con {
      width: 100%;
      height: 5.5rem;
      border: 0.02rem solid #0088ea;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.1rem;
      img {
        width: 100%;
      }
      span {
        height: 0.85rem;
        line-height: 0.85rem;
        font-size: 0.18rem;
        color: #000000;
      }
    }
  }
  .swiper_tit {
    width: 7rem;
    margin: 0 auto;
    ul {
      padding: 0;
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 0.57rem;
      margin-top: 0.44rem;
      li {
        cursor: pointer;
        width: 2.36rem;
        height: 0.6rem;
        background-color: #e5f3fd;
        color: #000000;
        font-size: 0.24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          margin-right: 0.18rem;
        }
        .swiper_img1 {
          width: 0.3rem;
          height: 0.2rem;
          background: url("../../assets/images/01首页/产品系统2.png") no-repeat;
        }
        .swiper_img2 {
          width: 0.3rem;
          height: 0.34rem;
          background: url("../../assets/images/01首页/解决方案1.png") no-repeat;
        }
        .swiper_img3 {
          width: 0.3rem;
          height: 0.3rem;
          background: url("../../assets/images/01首页/项目案例1.png") no-repeat;
        }
      }
      .blue {
        background-color: #0088ea;
        cursor: pointer;
        color: white;
        .swiper_img1 {
          background: url("../../assets/images/01首页/产品系统1.png") no-repeat;
        }
        .swiper_img2 {
          background: url("../../assets/images/01首页/解决方案 2.png") no-repeat;
        }
        .swiper_img3 {
          background: url("../../assets/images/01首页/项目案例2.png") no-repeat;
        }
      }
    }
  }
}
</style>